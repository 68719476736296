import * as d3 from 'd3';
import { forceManyBodySampled } from 'd3-force-sampled'
import { Component } from 'react';
import { withRouter } from "react-router-dom";
import './Graph.css';

class Graph extends Component {
    constructor(props) {
        super(props);
        this.simulation = null
        this.textInput = null;

        this.setTextInputRef = element => {
            this.textInput = element;
        };

        this.focusTextInput = () => {
            // Focus the text input using the raw DOM API
            if (this.textInput) this.textInput.focus();
        };
    }

    shouldComponentUpdate() {
        var d = this.getNodeFromId(this.props.all_data.nodes, this.props.selected_node_id)
        this.highlightNode(d)
        return false
    }

    componentDidMount() {
        this.drawGraph()
    }

    highlightNode(d) {
        let ctx = this


        this.svgLinks.attr('class', function (link) { return ctx.getLinkColor(d, link) })
        let neighbors = this.getNeighbors(d)

        this.svgNodes.attr('class', function (no) { return ctx.getNodeColor(no, neighbors) })
    }

    getNodeColor(d, neighbors) {
        if (this.isNeighborNode(d, neighbors)) {
            return "active"
        }
        return "ignored"
    }

    isNeighborNode(d, neighbors) {
        return (neighbors.indexOf(d.id) >= 0)
    }

    getNeighbors(node) {
        // could improve by using Set (duplicate due to multiple links)
        return this.props.all_data.links.reduce(function (neighbors, link) {
            if (link.target.id === node.id) {
                neighbors.push(link.source.id)
            } else if (link.source.id === node.id) {
                neighbors.push(link.target.id)
            }
            return neighbors
        },
            [node.id]
        )
    }

    getLinkColor(node, link) {
        // 
        return this.isNeighborLink(node, link) ? 'lineActive' : 'lineDisabled lineActive'
    }

    isNeighborLink(node, link) {
        return link.target.id === node.id || link.source.id === node.id
    }

    getNodeFromId(nodes, node_id) {
        let found_node;
        nodes.forEach((node) => {
            if (node.id === node_id) {
                found_node = node
            }
        })
        return found_node
    }


    drawGraph() {
        let ctx = this
        let realProps = this.props
        let grid = document.getElementById('graphGrid')
        let width = grid.clientWidth;
        let height = grid.clientHeight;

        const canvasHeight = "100%"
        const canvasWidth = "100%"
        const svgCanvas = d3.select(this.textInput)
            .append("svg")
            .attr("width", canvasWidth)
            .attr("height", canvasHeight)
        // .attr("preserveAspectRatio", "xMinYMin meet")
        // .attr("viewBox", "0 0 0 0")
        // .style("border-style", "solid")

        var all_data = this.props.all_data
        for (let key in all_data.nodes) {
            let node = all_data.nodes[key];
            if (node.id == "david_cameron") {
                node.fx = (width / 2);
                node.y = (height / 2);
            } else {
                node.x = (width / 2) + (Math.random() - 0.5) * width * 0.3;
                node.y = (height / 2) + (Math.random() - 0.5) * height * 0.3;
            }

        }

        function box_force(alpha) {
            for (var i = 0, n = ctx.svgNodes.length; i < n; ++i) {
                let curr_node = ctx.svgNodes[i];
                curr_node.x = 1;
                curr_node.y = 1;
            }
        }

        ctx.simulation = d3
            .forceSimulation(all_data.nodes)
            .velocityDecay(0.19)
            .force(
                "link",
                d3
                    .forceLink()
                    .id(function (d) {
                        return d.id;
                    })
                    .links(all_data.links)
                    .strength((0.08 - Math.min((1.2 * width / 1000), 0.07)))
            )
            // .force("strength", 30)
            .force("charge", d3.forceManyBody(2).theta(0).distanceMin(1).distanceMax(250).strength(-60))
            // .force("center", d3.forceCenter(width / 2, height / 2).strength(0.00035))
            // .force("charge", forceManyBodySampled().distanceMin(1).distanceMax(200).strength(-70))
            .force("collide", d3.forceCollide(30))
            // .force("box_force", box_force)
            // .force("center", d3.forceCenter(width, height))
            // .force("asdasdas", 100)
            // .force("radial", d3.forceRadial(width / 2, height / 2))
            .on("tick", ticked);


        var defs = svgCanvas.append("svg:defs");

        for (let key in all_data.nodes) {
            let node = all_data.nodes[key];
            defs.append("svg:pattern")
                .attr("width", "100%")
                .attr("height", "100%")
                .attr("patternContentUnits", "objectBoundingBox")
                .attr("id", "face_" + node.id)
                .append("svg:image")
                .attr("xlink:href", this.props.ROOT_URL + "face_icon_images/" + node.id + ".jpg")
                .attr("preserveAspectRatio", "none")
                .attr("width", "1")
                .attr("height", "1")
                .attr("x", 0)
                .attr("y", 0);
        }

        // default icon
        defs.append("svg:pattern")
            .attr("width", "100%")
            .attr("height", "100%")
            .attr("patternContentUnits", "objectBoundingBox")
            .attr("id", "face_default")
            .append("svg:image")
            .attr("xlink:href", this.props.ROOT_URL + "face_icon_images/default.jpg")
            .attr("preserveAspectRatio", "none")
            .attr("width", "1")
            .attr("height", "1")
            .attr("x", 0)
            .attr("y", 0);

        ctx.svgLinks = svgCanvas
            .append("g")
            .attr("class", "links lineDisabled")
            .selectAll("line")
            .data(all_data.links)
            .enter()
            .append("line")
            .attr("stroke-width", function (d) {
                return 3;
            });

        ctx.svgNodes = svgCanvas
            .append("g")
            .attr("class", "nodes")
            .selectAll("circle")
            .data(all_data.nodes)
            .enter()
            .append("circle")
            .attr("x", function () {
                return width / 2;
            })
            .attr("y", function () {
                return height / 2;
            })

            .attr("class", "ignored")
            .attr("r", function (d) {
                //     if (d.ignored === "True") return 20
                //     return 5;
                return 24 + Math.min(4 * (width / 1000), 8);
            }
            )

            .attr("fill", function (d) {
                // if (d.ignored === "True") {
                //     // return "url(#face_"+d.id+")"
                //     return "url(#face_david_cameron)"
                // }
                // return "red";

                if (d.face_image_source != "") {
                    return "url(#face_" + d.id + ")"
                } else {
                    return "url(#face_default)"
                }
            })
            .call(
                d3
                    .drag()
                    .on("start", dragstarted)
                    .on("drag", dragged)
                    .on("end", dragended)
            );

        ctx.svgNodes.on("mouseover", function (event, d) {
            // var g = d3.select(this); // The node
            // g.attr("")
            // // The class is used to remove the additional text later
            // 
            // g.attr("class", "active");

        })

        ctx.svgNodes.on("mouseout", function (event, d) {
            // var g = d3.select(this); // The node
            // g.attr("")
            // // The class is used to remove the additional text later
            // 
            // g.attr("class", "ignored");
        })

        ctx.svgNodes.on("click", function (event, d) {
            // var g = d3.select(this); // The node
            // ctx.highlightNode(d)
            realProps.history.push("/explore/" + d.id);
        })


        function ticked() {
            ctx.svgLinks
                .attr("x1", function (d) {
                    return d.source.x;
                })
                .attr("y1", function (d) {
                    return d.source.y;
                })
                .attr("x2", function (d) {
                    return d.target.x;
                })
                .attr("y2", function (d) {
                    return d.target.y;
                });

            ctx.svgNodes
                // .attr("cx", function (d) {
                //     return d.x;
                // })
                // .attr("cy", function (d) {
                //     return d.y;
                // });
                .attr("cx", function (d) {
                    if (d.id == "david_cameron") {
                        return d.x = width / 2
                    } else {
                        return d.x = Math.max(20, Math.min(width - 20, d.x));
                    }
                })
                .attr("cy", function (d) {
                    if (d.id == "david_cameron") {
                        return d.y = height / 2
                    } else {
                        return d.y = Math.max(20, Math.min(height - 20, d.y));
                    }
                });

            // svgNodes[0].x = width / 2
            // svgNodes[0].y = height / 2
        }

        function dragstarted(d) {
            // if (!d3.event.active) 
            ctx.simulation.alphaTarget(0.1).restart();
            d.fx = d.x;
            d.fy = d.y;
            d3.select(this).select(".nodes").select("circle").attr("class", "ignored");
        }

        function dragged(event, d) {
            if (d.id == "david_cameron") {
                return
            }
            d.fx = event.x;
            d.fy = event.y;
        }

        function dragended(d) {
            // d.fx = null;
            // d.fy = null;
        }

        // var n = 10000
        // for (var i = 0; i < n; ++i) ctx.simulation.tick();
        // // simulation.stop();
        // ctx.simulation.alphaTarget(0.1).restart();
        // simulation.stop();
    }
    render() {
        return (<div className="canvasHolder" ref={this.setTextInputRef} > </div>)
    }
}


export default withRouter(Graph);
