import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import CreateIcon from '@material-ui/icons/Create';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import MenuIcon from '@material-ui/icons/Menu';
import TimelineIcon from '@material-ui/icons/Timeline';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionDown = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        fontFamily: "Playfair Display",
        // textAlign: "left",
        flexGrow: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
}));

export default function TopNav() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [state, setState] = React.useState({
        left: false,
    });

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {/* {['Home', 'Timeline', 'Inquiry Tracker', 'About'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))} */}
                <ListItem button key={'Explore'} component={Link} to="/explore/david_cameron">
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    <ListItemText primary={'Explore'} />
                </ListItem>
                <ListItem button key={'Timeline'} component={Link} to="/timeline">
                    <ListItemIcon><TimelineIcon /></ListItemIcon>
                    <ListItemText primary={'Timeline'} />
                </ListItem>
                <ListItem button key={'Inquiry Tracker'} component={Link} to="/inquiry-tracker">
                    <ListItemIcon><LocationSearchingIcon /></ListItemIcon>
                    <ListItemText primary={'Inquiry Tracker'} />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button key={'FAQ'} onClick={handleClickOpen}>
                    <ListItemIcon><InfoIcon /></ListItemIcon>
                    <ListItemText primary={'FAQ'} />
                </ListItem>
                <ListItem button key={'Contact'} onClick={handleClickOpen1}>
                    <ListItemIcon><AlternateEmailIcon /></ListItemIcon>
                    <ListItemText primary={'Contact'} />
                </ListItem>
                <ListItem button key={'Volunteer'} onClick={handleClickOpen2}>
                    <ListItemIcon><CreateIcon /></ListItemIcon>
                    <ListItemText primary={'Volunteer'} />
                </ListItem>
                <ListItem button key={'Privacy'} onClick={handleClickOpen3}>
                    <ListItemIcon><VpnKeyIcon /></ListItemIcon>
                    <ListItemText primary={'Privacy'} />
                </ListItem>

            </List>
        </div>
    );


    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const handleClickOpen3 = () => {
        setOpen3(true);
    };
    const handleClose3 = () => {
        setOpen3(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };


    const handleClickOpen1 = () => {
        setOpen1(true);
    };
    const handleClose1 = () => {
        setOpen1(false);
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // const handleDrawerOpen = () => {
    //     toggleDrawer('left', true);
    // }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer('left', true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography edge="start" variant="h6" className={classes.title}>
                        Greensill Scandal Archive
                    </Typography>
                    <IconButton
                        edge="end"
                        onClick={handleClickOpen}
                        color="inherit"
                    >
                        <InfoIcon />
                    </IconButton>
                    <IconButton
                        edge="end"
                        onClick={handleClickOpen1}
                        color="inherit"
                    >
                        <AlternateEmailIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">FAQ 🤔</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <b>What is this app? 🙂</b>
                        <br />
                        I developed this in response to the growing mountain ⛰️ of evidence on the Greensill
                        Scandal 💩. I hope this will make it easy to explore and understand how everything unfolded.
                        The public should have the power to explore this secretive world... 🕵️‍♂️
                        <br />
                        A central, collaborative app drawing together and organising data from various journalists.
                        <br /> <br />
                        <b>Can I share screenshots of this site?</b>
                        <br />
                        Yes we are an open archive, although of course it's your responsibility to ensure you have proper licensing for any images - where possible we've used images under Creative Commons.
                        <br /><br /><br />
                        Oh, and huge credits 🏆 to the Journalists who intially uncovered this scandal: <a href="https://twitter.com/BondHack">Robert Smith</a> (FT), <a href="https://twitter.com/PickardJE">@PickardJE</a> (FT), <a href="https://twitter.com/cynthiao">Cynthia O’Murchú</a> (FT), <a href="https://twitter.com/michael_pooler" target="_blank" rel="noreferrer">@Michael_Pooler</a> (FT), <a href="https://twitter.com/Gabriel_Pogrund">@Gabriel_Pogrund</a> (The Sunday Times), <a href="https://twitter.com/jcollingridgeST">John Collingridge</a> (The Sunday Times).
                    </DialogContentText>
                    {/* <a href="https://twitter.com/joepike">@joepike</a> (Sky) */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                     </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open1}
                TransitionComponent={TransitionDown}
                keepMounted
                onClose={handleClose1}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Contact Info</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        You want to send me corrections, tips or ideas? 😉 Sure thing: <a href={"mailto:PoliticalQuant@protonmail.com"}>PoliticalQuant@protonmail.com</a>
                        <br /><br />All take-down requests, Letters before Action and hate-mail should be directed to  <a href={"mailto:PoliticalQuant+spam@protonmail.com"}>PoliticalQuant+spam@protonmail.com</a>. Thank you.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose1} color="primary">
                        Close
                     </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open2}
                TransitionComponent={TransitionDown}
                keepMounted
                onClose={handleClose2}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">We need you!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Join our team of volunteers in creating a <b>high-quality archive</b> of the Greensill Scandal. <b>No coding required.</b> Volunteering is easy. Just update a spreadsheet with the latest scoops in the news!
                        <br /><br />
                        The three key areas you could help out: adding new links (or events) between people from news articles, updating the Inquiry Tracker, adding images.
                        <br /><br />
                        Just drop an email to PoliticalQuant+volunteer@protonmail.com for more info.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose2} color="primary">
                        Close
                     </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open3}
                TransitionComponent={TransitionDown}
                keepMounted
                onClose={handleClose3}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Privacy Policy</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div><h1>Privacy Policy for GreensillScandal.com</h1>

                            <p>At GreensillScandal.com, accessible from GreensillScandal.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by GreensillScandal.com and how we use it.</p>

                            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

                            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in GreensillScandal.com. This policy is not applicable to any information collected offline or via channels other than this website. Our Privacy Policy was created with the help of the <a href="https://www.privacypolicyonline.com/privacy-policy-generator/">Online Generator of Privacy Policy</a>.</p>

                            <h2>Consent</h2>

                            <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

                            <h2>Information we collect</h2>

                            <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
                            <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
                            <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>

                            <h2>How we use your information</h2>

                            <p>We use the information we collect in various ways, including to:</p>

                            <ul>
                                <li>Provide, operate, and maintain our website</li>
                                <li>Improve, personalize, and expand our website</li>
                                <li>Understand and analyze how you use our website</li>
                                <li>Develop new products, services, features, and functionality</li>
                                <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                                <li>Send you emails</li>
                                <li>Find and prevent fraud</li>
                            </ul>

                            <h2>Log Files</h2>

                            <p>GreensillScandal.com follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

                            <h2>Cookies and Web Beacons</h2>

                            <p>Like any other website, GreensillScandal.com uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>

                            <p>For more general information on cookies, please read <a href="https://www.privacypolicyonline.com/what-are-cookies/">"What Are Cookies" from Cookie Consent</a>.</p>



                            <h2>Advertising Partners Privacy Policies</h2>

                            <p>You may consult this list to find the Privacy Policy for each of the advertising partners of GreensillScandal.com.</p>

                            <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on GreensillScandal.com, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

                            <p>Note that GreensillScandal.com has no access to or control over these cookies that are used by third-party advertisers.</p>

                            <h2>Third Party Privacy Policies</h2>

                            <p>GreensillScandal.com's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

                            <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

                            <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

                            <p>Under the CCPA, among other rights, California consumers have the right to:</p>
                            <p>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
                            <p>Request that a business delete any personal data about the consumer that a business has collected.</p>
                            <p>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
                            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

                            <h2>GDPR Data Protection Rights</h2>

                            <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
                            <p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
                            <p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
                            <p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
                            <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
                            <p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
                            <p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
                            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

                            <h2>Children's Information</h2>

                            <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

                            <p>GreensillScandal.com does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose3} color="primary">
                        Close
                     </Button>
                </DialogActions>
            </Dialog>
            <div>
                <React.Fragment key={'left'}>
                    <SwipeableDrawer
                        anchor={'left'}
                        open={state['left']}
                        onClose={toggleDrawer('left', false)}
                        onOpen={toggleDrawer('left', true)}
                    >
                        {list('left')}
                    </SwipeableDrawer>
                </React.Fragment>
            </div>
        </div >
    );
}
