import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Fragment } from "react";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: 4,
        minHeight: '100%',
        marginTop: 30,
    },
    paper: {
        maxWidth: 400,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: '0 auto'
    },
    container: {
        paddingTop: '50px',
        width: "100%",
        textAlign: 'center',
    },
    heading: {
        paddingLeft: 20,
        textAlign: 'left',
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '90%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    eventItem: {

        width: "100%",
        display: "inline-block"
    },
    eventDate: {
        color: 'grey',
        // fontSize: 10,
    },
    badgeHodler: {
        width: 40
    },
    heightThing: {
        height: '20vh',
    }

}));

const StyledBadge = withStyles((theme) => ({
    badge: {
        margin: '0',
        position: 'absolute',
        top: "50%",
        left: 0,
        // transform: "translateY(-50%)",
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },

    },
}))(Badge);

const StyledBadgeRed = withStyles((theme) => ({
    badge: {
        margin: '0',
        position: 'absolute',
        top: "50%",
        left: 0,
        // transform: "translateY(-50%)",
        backgroundColor: '#b70034',
        color: '#b70034',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },

    },
}))(Badge);



const InquiryTrackerScreen = ({ all_data }) => {
    // const [expanded, setExpanded] = React.useState(false);
    // // const handleChange = (panel) => (event, isExpanded) => {
    // //     setExpanded(isExpanded ? panel : false);
    // // };
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const classes = useStyles();
    return (
        <Fragment>
            {/* <div className={classes.container}>
                <Card className={classes.paper}>
                    <h1>Inquiry Tracker | Coming Soon...</h1>
                    <div>Why do we need this? Well there's 7 ongoing inquiries - yes that's right, 7. Wait actually maybe 8 if you count the Audit Office one. Well someone better keep track of these...   </div>
                </Card>
            </div> */}
            <Container maxWidth="md">
                <div className={classes.root}>
                    {all_data.inquiries.map((e, i) => {
                        let accordianId = "panel" + i;
                        return (
                            <Accordion key={accordianId} expanded={expanded === accordianId} onChange={handleChange(accordianId)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className={classes.badgeHodler}>
                                        {(e.status === "active")
                                            ? (
                                                <StyledBadge
                                                    overlap="circle"
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    variant="dot"
                                                >
                                                    {/* <Avatar alt="Remy Sharp" src="material-ui.com/static/images/avatar/1.jpg" /> */}
                                                </StyledBadge>
                                            )
                                            : (e.status === "finished")
                                                ? < DoneIcon style={{ position: 'absolute', left: 20 }} />
                                                : <StyledBadgeRed
                                                    overlap="circle"
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    variant="dot"
                                                >
                                                    {/* <Avatar alt="Remy Sharp" src="material-ui.com/static/images/avatar/1.jpg" /> */}
                                                </StyledBadgeRed>
                                        }
                                    </div>

                                    <Typography className={classes.heading}>{e.inquiry_name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>
                                        {
                                            ((typeof e.website !== 'undefined') && (e.website != ""))
                                                ? <Button href={e.website} variant="contained" target="_blank" rel="noreferrer">
                                                    external webpage
                                            </Button>
                                                : null
                                        }
                                        {e.updates.map((update, i) => {
                                            let accordianUpdateId = "panelUpdate" + i;
                                            return (
                                                <div key={accordianUpdateId}>
                                                    <Divider style={{ margin: 10 }} />
                                                    <a href={update.article_link} target="_blank" rel="noreferrer">
                                                        <div className={classes.eventDate}>{update.date}</div>
                                                    </a>
                                                    <span className={classes.eventItem}>
                                                        {update.info}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </div>
            </Container>
            <div className={classes.heightThing}>

            </div>
        </Fragment >
    );
};

export default InquiryTrackerScreen;