import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ShareOutlined } from '@material-ui/icons';
import GavelIcon from '@material-ui/icons/Gavel';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TwitterIcon from '@material-ui/icons/Twitter';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from "react-router";
import { Link } from 'react-router-dom';
import { FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TelegramIcon, TelegramShareButton, TwitterIcon as TwitterIconShare, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import Slider from "react-slick";
import './EntityDetail.css';

function date_day_to_written(date_day) {
    let final_char = date_day.charAt(date_day.length - 1)
    if (final_char === "1") {
        return "st"
    } else if (final_char === "3") {
        return "rd"
    } else if (final_char === "2") {
        return "nd"
    }
    return "th"
}

var shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
function month_index_to_short(index) {
    return shortMonths[index];
}

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    cardIcons: {
        float: "right"
    },
    img: {
        height: 255,
        display: 'block',
        maxWidth: 400,
        overflow: 'hidden',
        width: '100%',
    },
    entityLinks: {
        // height: 250,
        maxWidth: 345,
        marginTop: 20,
    },
    sliderItem: {
        // height: "100%"
    },
    slickContainer: {
        // height: "100%"
    },
    sliderControlContainer: {
        outline: 'none!important',
        margin: "0 auto",
        // height: "100px",
    },
    dateSlider: {
        maxWidth: "100px",
        margin: "0 auto"
    },
    smallAvatar: {
        display: "inline-block",
        width: 30,
        height: 30,
        marginLeft: 5,
        marginRight: 5,
        outline: 'none',
    },
    dateYear: {
        fontSize: "4px"
    },
    dateContainer: {
        lineHeight: 1,
        marginTop: 4,
    },
    eventDescription: {
        marginTop: "10px"
    },
    backdrop: {
        color: "#fff",
        zIndex: 10,
    },
    wikipedia: {

    }
});

const TransitionDown = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


function SamplePrevArrow(props) {
    const { style } = props;
    return (
        <div
            style={{ ...style, display: "none", }}
        />
    );
}

// function get_number_of_events(data, node_id) {
//     let count = 0;
//     data.events.map((event, i) => {
//         if (event.people.indexOf(node_id) === -1) {
//         } else {
//             count += 1
//         }
//     }
//     )
//     return count
// }

let mainSlider;
let dateSlider;

function get_node_index_by_id(data, node_id) {
    let index = 0
    data.nodes.forEach((node, i) => {
    });

    data.nodes.every(function (node) {
        if (node.id === node_id) {
            return false;
        }
        index += 1
        return true;
    });
    return index
}

// function get_event_index_by_id(data, event_id, node_id) {
//     let index = 0
//     let break_flag = false
//     data.events.forEach((event) => {
//         if (!break_flag) {
//             if (parseInt(event.id) == event_id) {
//                 break_flag = true
//                 return
//             }
//             if (node_in_event(event, node_id)) {
//                 index += 1
//             }
//         }
//     });
//     if (!break_flag) return 0
//     return index
// }

// function node_in_event(event, node_id) {
//     let flag = false
//     event.people.forEach((person) => {
//         if (flag) return
//         if (person === node_id) {
//             flag = true
//             return
//         }
//     })
//     return flag
// }

function filter_events(events, node_id) {
    let clean_events = []
    events.map((event, i) => {
        if (event.people.indexOf(node_id) >= 0) {
            clean_events.push(event)
        }
        return null
    })
    return clean_events
}

function event_index_by_id(events, int_id) {
    let int_index = 0
    events.map((event, i) => {
        if (parseInt(event.id) === parseInt(int_id)) {
            int_index = i
        }
        return null
    })
    return int_index
}

function EntityDetail({ all_data, node_id, event_id, ROOT_URL, update_count }) {
    const [open2, setOpen2] = React.useState(false);
    const [shareUrl, setShareUrl] = React.useState("GreensillScandal.com");
    // let shareUrl = 'https://asdasd.com';
    const title = 'Explore the Greensill Scandal!';


    const handleClickOpen2 = () => {
        setShareUrl("GreensillScandal.com" + window.location.pathname)
        setOpen2(true);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };




    let mounting = false
    const history = useHistory();



    // let clean_events = useMemo(() => filter_events(all_data.events, node_id), [node_id])
    let clean_events = filter_events(all_data.events, node_id);

    let initialStep;
    if (typeof event_id !== 'undefined') {
        initialStep = event_index_by_id(clean_events, event_id)
    } else {
        initialStep = 0
    }
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(() => {
        return initialStep
    });

    // if (initialStep != activeStep) {
    //     
    //     setActiveStep(initialStep)
    // }

    // setActiveStep(initialStep)
    // const [carousel, setCarousel] = React.useState(0);
    // const theme = useTheme();

    let current_node = all_data.nodes[get_node_index_by_id(all_data, node_id)]


    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    const handleHistory = (nextIndex) => {
        history.push({
            pathname: "/explore/" + node_id + "/" + clean_events[nextIndex].id
        });

    }

    useEffect(() => {
        mounting = true
        var xhr = new XMLHttpRequest()
        // get a callback when the server responds
        xhr.addEventListener('load', () => {
            // update the state of the component with the result here
            let count = JSON.parse(xhr.responseText).value
            update_count(count)
        })
        // open the request with the verb and the url
        xhr.open('GET', 'https://api.countapi.xyz/hit/asdjkasjdlkajdaiajdliqjeqlkjsldjasqwieaskldjalwialjwd')
        // send the request
        xhr.send()
    }, [])


    useEffect(() => {
    }, [event_id])

    useEffect(() => {



        if (!mounting) {
            setActiveStep(0)
            mainSlider.slickGoTo(0)
            dateSlider.slickGoTo(0)
        }
        // setCleanevents(filter_events(all_data.events, node_id))
    }, [node_id])





    const settings = {
        lazyLoad: 'progressive',
        initialSlide: initialStep,
        // dots: true,
        // infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => {
            if (current != activeStep) {

                // next = activeStep
                // return
            }
            setActiveStep(next);
            // alert(next);
            dateSlider.slickGoTo(next)
            handleHistory(next)
        },
        afterChange: (current) => {
            // this is needed to ensure they are synced when user spams prev/next
            dateSlider.slickGoTo(current)
        },
    };

    const settingsDate = {
        initialSlide: activeStep,
        swipeToSlide: false,
        swipe: false,
        // dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SamplePrevArrow />,
        prevArrow: <SamplePrevArrow />,
        // beforeChange: (current, next) => {
        //     setActiveStep(next);
        //     // alert(next);
        // }
    };

    // const popupImageSettings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    // };

    const handleNext = () => {
        let next = activeStep + 1

        // setActiveStep((prevActiveStep) => next);
        mainSlider.slickGoTo(next)
        dateSlider.slickGoTo(next)
        // mainSlider.slickNext()
        // dateSlider.slickNext()
    };

    const handleBack = () => {
        let prev = activeStep - 1


        // setActiveStep((prevActiveStep) => prev);
        // slider.slickGoTo(prev)
        mainSlider.slickGoTo(prev)
        dateSlider.slickGoTo(prev)
        // mainSlider.slickPrev()
        // dateSlider.slickPrev()
    };

    const mainSliderDates = (props) => {
        return (
            <Slider ref={c => (dateSlider = c)} {...settingsDate} className={classes.dateSlider}>
                {
                    clean_events.map((event, i) => {
                        return (
                            <div key={i} className={classes.dateContainer}>
                                <span>
                                    {
                                        (event.start_date.day != "")
                                            ? event.start_date.day + date_day_to_written(event.start_date.day.toString()) + " "
                                            : null
                                    }
                                    {
                                        month_index_to_short(event.start_date.month)
                                    }
                                    <br />
                                    <span style={{ fontSize: 10, color: 'grey' }}>{event.start_date.year}</span>
                                </span>
                            </div>
                        )
                    }
                    )
                }
            </Slider>
        )
    }

    const mainSliderCards = (props) => {
        return (
            <Slider ref={c => (mainSlider = c)} {...settings} className={classes.sliderContainer}>
                {clean_events.map((event, i) => {
                    return (
                        <div className={classes.sliderItem} key={i}>
                            <CardContent style={{ paddingTop: 0, overflowY: 'auto', maxHeight: 200 }}>
                                {event.people.map((person, j) => {
                                    // let node_index = get_node_index_by_id(all_data, person)

                                    let icon_url = ROOT_URL + "face_icon_images/default.jpg"
                                    let title_text = ""
                                    let person_node = all_data.nodes[get_node_index_by_id(all_data, person)]
                                    // let person_node = all_data.nodes[0]
                                    if (person_node.face_image_source != "") {
                                        icon_url = ROOT_URL + "face_icon_images/" + person + ".jpg"
                                        title_text = "Image Source: " + person_node.face_image_source
                                    }
                                    return (
                                        <Avatar title={title_text} component={Link} to={"/explore/" + person} key={j} className={classes.smallAvatar} src={icon_url} />
                                    )
                                })}
                                <div className={classes.eventDescription}>{event.text.text_raw}</div>
                            </CardContent>
                            <div>
                                {
                                    (event.media.url != "" && !(event.media.url.includes("profile_banner_images")))
                                        // only show image button if there's a real image, not just placeholder banner
                                        ?
                                        <Button onClick={handleToggle}>
                                            <ImageOutlinedIcon />
                                        </Button>
                                        : null
                                }
                                {
                                    (event.article_link != "")
                                        ?
                                        <Button href={event.article_link} target="_blank" rel="noreferrer">
                                            <OpenInNewIcon />
                                        </Button>
                                        : null
                                }
                                <Button onClick={handleClickOpen2}>
                                    <ShareOutlined />
                                </Button>
                            </div>
                        </div>
                    )
                })
                }
            </Slider >
        )
    }

    const cardProfile = (props) => {
        return (
            <div style={{ minHeight: "42vh" }}>
                <Card className={classes.root}>
                    <CardActionArea>
                        <CardMedia
                            className={classes.media}
                            image={
                                (current_node.banner_image_source != "")
                                    ? ROOT_URL + 'profile_banner_images/' + current_node.id + '.jpg'
                                    : ROOT_URL + 'profile_banner_images/default.jpg'
                            }
                            title={"Image Source: " + current_node.banner_image_source}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {current_node.display_name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {current_node.short_bio}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions style={{ display: 'block' }}>
                        {
                            current_node.twitter_handle != ""
                                ?
                                <a href={'https://twitter.com/' + current_node.twitter_handle}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={classes.cardIcons}
                                    style={{ "margin-left": "8px" }}
                                >
                                    <TwitterIcon />
                                </a>
                                : null
                        }
                        {
                            current_node.wikipedia_handle != ""
                                ?
                                <a href={'https://en.wikipedia.org/wiki/' + current_node.wikipedia_handle}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={classes.cardIcons}
                                >
                                    <img alt="" height={25} width={25} src={ROOT_URL + "/other_static/wikipedia_logo.svg"} className={classes.wikipedia} />
                                </a>
                                : null
                        }
                        {
                            current_node.gov_uk_profile != ""
                                ?
                                <a href={current_node.gov_uk_profile}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={classes.cardIcons}
                                >
                                    <GavelIcon />
                                </a>
                                : null
                        }
                    </CardActions>
                </Card >
            </div>
        )
    }
    return (
        <div>
            {/* Entity profile */}
            {useMemo(() => cardProfile(), [node_id])}

            {/* Entity event information */}
            <div style={{ minHeight: "40vh" }}>
                <Card className={classes.entityLinks}>
                    <CardActions>
                        <div className={classes.sliderControlContainer}>
                            {/* <a href={entity.twitter}
                            target="_blank"
                            rel="noreferrer"
                            className={classes.cardIcons}
                        >
                            <TwitterIcon />
                        </a> */}
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                        <KeyboardArrowLeft />
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    {useMemo(() => mainSliderDates(), [node_id])}
                                </Grid>
                                <Grid item xs={4}>
                                    {
                                        <Button size="small" onClick={handleNext} disabled={activeStep === (clean_events.length - 1)}>
                                            <KeyboardArrowRight />
                                        </Button>
                                    }
                                </Grid>
                            </Grid>

                        </div>
                    </CardActions>
                    {useMemo(() => mainSliderCards(), [node_id])}
                </Card>
            </div>
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                <div>
                    <img alt="" src={ROOT_URL + "profile_banner_images/default.jpg"} className="backdropImage" />
                    <div>Source: Daily Mail</div>
                </div>
            </Backdrop>
            <Dialog
                open={open2}
                TransitionComponent={TransitionDown}
                keepMounted
                onClose={handleClose2}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Share this specific event</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <FacebookMessengerShareButton
                            url={shareUrl}
                            appId="521270401588372"
                            className="Demo__some-network__share-button"
                        >
                            <FacebookMessengerIcon size={32} round />
                        </FacebookMessengerShareButton>
                        <FacebookShareButton
                            url={shareUrl}
                            quote={title}
                            className="Demo__some-network__share-button"
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <TwitterShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                        >
                            <TwitterIconShare size={32} round />
                        </TwitterShareButton>

                        <TelegramShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                        >
                            <TelegramIcon size={32} round />
                        </TelegramShareButton>
                        <WhatsappShareButton
                            url={shareUrl}
                            title={title}
                            separator=":: "
                            className="Demo__some-network__share-button"
                        >
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                        <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose2} color="primary">
                        Close
                     </Button>
                </DialogActions>
            </Dialog>
        </div >

    );
}

export default React.memo(EntityDetail)