import { makeStyles } from '@material-ui/core/styles';
import React, { useRef } from 'react';
// // import Timeline from '@material-ui/lab/Timeline';
// import TimelineConnector from '@material-ui/lab/TimelineConnector';
// import TimelineContent from '@material-ui/lab/TimelineContent';
// import TimelineDot from '@material-ui/lab/TimelineDot';
// import TimelineItem from '@material-ui/lab/TimelineItem';
// import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
// import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TL from './TL';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

let timelineEl;

export default function CustomTimeline({ all_data }) {
    let data = { "events": all_data.events }
    let tl_data = JSON.parse(JSON.stringify(data));
    const timelineEl = useRef(null);

    React.useEffect(() => {
        // alert("mounted")
        if (timelineEl.current) {
            // const timeline = new TL.Timeline(timelineEl.current, "https://docs.google.com/spreadsheets/d/1J3CruMnEBaJjoK1y6_MdGrKrkRvhlR2SI-o1PywpXw4/edit#gid=0");
            const timeline = new TL.Timeline(timelineEl.current, tl_data);
        }
    }, []);


    return (
        <div>
            <div ref={timelineEl} className="timeline" style={{ "width": "100%", "min-height": "90vh" }}></div>
        </div>
    );
}
