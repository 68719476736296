// import usePageTracking from './usePageTracking'
import { useEffect, useState } from "react";
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const handleAcceptCookie = () => {
};

const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
};

const usePageTracking = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {

        // if (!window.location.href.includes("localhost")) {
        const isConsent = getCookieConsentValue();
        if (isConsent === true) {
            ReactGA.initialize("UA-195234628-1");
            setInitialized(true);
        }
        // }
    }, []);

    useEffect(() => {

        const isConsent = getCookieConsentValue();

        if (isConsent === "true") {

        } else {

        }

        if (!initialized && (isConsent === "true")) {

            ReactGA.initialize("UA-195234628-1");
            setInitialized(true);
        }

        if (isConsent === "true") {

            ReactGA.pageview(location.pathname + location.search);
        }
    }, [initialized, location]);
};

export default function GAHandler() {
    return (
        <div>
            <CookieConsent enableDeclineButton
                onAccept={handleAcceptCookie}
                onDecline={handleDeclineCookie}
            >
                Cookies 🍪 are used to improve user experience.
            </CookieConsent>
            {usePageTracking()}
        </div>
    )
}

