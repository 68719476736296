import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import EntityDetail from '../../components/EntityDetail';
import Graph from '../../components/Graph';
// import ReactGa from 'react-ga';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: 4,
        minHeight: '100%'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));



export default function NetworkScreen({ all_data, ROOT_URL, update_count }) {
    // const id = props.eventId.match.params.eventId;
    const { name, interaction_id } = useParams()

    // constructor(props) {
    //     super(props);
    // }

    const classes = useStyles();
    return (
        <Fragment>
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={12}
                        sm={6}
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        // justify="center"
                        style={{ minHeight: '90vh', paddingTop: 20 }}

                    >

                        <EntityDetail all_data={all_data} node_id={name} interaction_id={interaction_id} ROOT_URL={ROOT_URL} update_count={update_count} />
                    </Grid>
                    {/* <Grid item xs={2} sm={2}>
                        somehow centre divider
                        <Divider orientation="vertical" />
                    </Grid> */}

                    <Grid item xs={12}
                        id="graphGrid"
                        sm={6}
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ height: '90vh' }}
                    >
                        <Graph all_data={all_data} selected_node_id={name} ROOT_URL={ROOT_URL} />
                    </Grid>
                </Grid>
            </div>
        </Fragment >
    )
}

// export default NetworkScreen;