
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import './Footer.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    madeBy: {
        color: 'rgba(255,255,255,0.8)',
        marginBottom: 60,
    },
    thanks: {
        color: 'rgba(255,255,255,0.8)',
        margin: "0 auto",
        maxWidth: 600,
    },
    emoji: {
        fontSize: 18
    },
    logo: {
        margin: 15,
        height: 35
    }
}))

// const ClickHandler = () => {
//     ReactGA.event({
//         category: 'Button',
//         action: 'New click!'
//     })
//     
// }

export default function Footer({ ROOT_URL, onMount }) {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    useEffect(() => {
        onMount([value, setValue]);
    }, [onMount, value]);

    return (
        <div className='footer'>
            {/* <button onClick={ClickHandler}>Click me</button> */}

            {/* <Typography edge="start" variant="h6" className={classes.madeBy}> */}
            <div className={classes.madeBy}>
                {
                    value > 0
                        ? ("🔥 " + value + " views 👀")
                        : null
                }
                <br />
                made and maintained by <a href="https://twitter.com/PoliticalQuant" target="_blank" rel="noreferrer">@PoliticalQuant</a> <span className={classes.emoji}>🐱‍💻</span>
                <br />
                (blame me for all the errors 🙂)
            </div>
            <div className={classes.thanks}>
                <b>huge credits 🏆 to The Sunday Times and Financial Times for uncovering this scandal:</b>
                <br />
                <br />
                <a href="https://twitter.com/Gabriel_Pogrund" target="_blank" rel="noreferrer">Gabriel Pogrund</a> <a href="https://twitter.com/jcollingridgeST" target="_blank" rel="noreferrer">John Collingridge</a> <a href="https://twitter.com/BondHack" target="_blank" rel="noreferrer">Robert Smith</a> <a href="https://twitter.com/PickardJE" target="_blank" rel="noreferrer">Jim Pickard</a> <a href="https://twitter.com/cynthiao" target="_blank" rel="noreferrer">Cynthia O’Murchú</a> <a href="https://twitter.com/michael_pooler" target="_blank" rel="noreferrer">Michael Pooler</a>
                <br />
                <br />
            </div>

            <a href="https://thetimes.co.uk" target="_blank" rel="noreferrer"><img alt="" src={ROOT_URL + "/other_static/thetimes.jpg"} className={classes.logo}></img></a>
            <a href="https://ft.com" target="_blank" rel="noreferrer"><img alt="" src={ROOT_URL + "/other_static/ft.jpg"} className={classes.logo}></img></a >
            <br />
            <div className={classes.thanks}>
            </div>

            {/* </Typography> */}
        </div >
    )
}